import { createSlice } from "@reduxjs/toolkit";

export interface searchState {
  state: Number;
}
const initialState: searchState = {
  // 1 工作  2 课程
  state: 1, 
};

// 创建一个 Slice
export const searchState = createSlice({
  name: "searchState",
  initialState,
  // 定义 reducers 并生成关联的操作
  reducers: {
    setSearchState(serarch, { payload }){
      serarch.state =payload.operation;
    },
   
  },
});

// 导出 reducers 方法
export const { setSearchState } = searchState.actions;

// 默认导出
export default searchState.reducer;