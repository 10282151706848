import { createSlice } from "@reduxjs/toolkit";

export interface accountState {
  info: {}
}
const initialState: accountState = {
  info: {}
};

// 创建一个 Slice
export const accountInfo = createSlice({
  name: "accountInfo",
  initialState,
  // 定义 reducers 并生成关联的操作
  reducers: {
    setAccountInfo(state, { payload }){
      state.info = {
        ...payload.info
      };
    }
  },
});

// 导出 reducers 方法
export const { setAccountInfo } = accountInfo.actions;

// 默认导出
export default accountInfo.reducer;