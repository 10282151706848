import { createSlice } from "@reduxjs/toolkit";

export interface LoginState {
  doubleTreeEject: boolean;
  singleTreeEject: boolean;
  searchAreaEject:boolean;
}
const initialState: LoginState = {
  doubleTreeEject: false,
  singleTreeEject:false,
  searchAreaEject:false
};

// 创建一个 Slice
export const ejectState = createSlice({
  name: "ejectState",
  initialState,
  // 定义 reducers 并生成关联的操作
  reducers: {
    setDoubleTreeEject(state, { payload }){
      const data = JSON.stringify(payload.operation);
      sessionStorage.setItem("setDoubleTreeEject", data);
      state.doubleTreeEject = payload.operation;
    },
    setSingleTreeEject(state, { payload }){
      const data = JSON.stringify(payload.operation);
      sessionStorage.setItem("setSingleTreeEject", data);
      state.singleTreeEject = payload.operation;
    },
    setSearchAreaEject(state, { payload }){
      const data = JSON.stringify(payload.operation);
      sessionStorage.setItem("setSearchAreaEject", data);
      state.searchAreaEject = payload.operation;
    }
  },
});

// 导出 reducers 方法
export const { setDoubleTreeEject,setSingleTreeEject,setSearchAreaEject } = ejectState.actions;

// 默认导出
export default ejectState.reducer;