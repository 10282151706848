import * as React from "react";
import "./index.scss";

const Footer = () => {
  return (
    <div className="copyright-component">
      版权所有 © 2023 武汉德特云才 <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2022018761号-1</a>
    </div>
  );
};

export default Footer;