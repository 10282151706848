import { createSlice } from "@reduxjs/toolkit";

interface ModalLogin {
  visible: false
  toPage: {
    type: "health" | "discard"; // health: 这个链接可以跳转  cancel: 这个链接已经取消了，不可用
    url: any
  }
}

const modalLoginInit: ModalLogin = {
  visible: false,
  toPage: {
    type: "discard",
    url: ""
  }
};

const modalLoginState = createSlice({
  name: "modalLoginState",
  initialState: modalLoginInit,
  reducers: {
    setModalLogin(state, { payload }) {
      const { visible, toPage } = payload;
      state.visible = visible;
      state.toPage = toPage;
    }
  }
});


// 导出 reducers 方法
export const { setModalLogin } = modalLoginState.actions;

// 默认导出
export default modalLoginState.reducer;